﻿// Fonts
$font-family-base: 'Inktrap', sans-serif;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1232px,
    xxl: 1440px,
    xxxl: 1600px,
    xxxxl: 1920px
);

$container-max-widths: (
    lg: 65rem,
    xl: 70rem,
    xxl: 75rem,
    xxxl: 80rem,
    xxxxl: 90rem
);

$mobile-menu: lg;
$navbar-height: 5rem;

// Grid
$grid-columns: 24;
$grid-gutter-width: 1.5rem;

$border-radius: .5rem;

// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-short-smoothing: ease-in-out;
$transition-short-time: .15s;
$transition-short: all $transition-short-time $transition-short-smoothing;