.tag {
    // Default tag is gray and solid
    @extend .caption;
    display: inline-block;
    padding: .5rem 1rem;
    border-radius: 2.5rem;
    background: $color-black-40;
    color: $color-white-default;
    $block: &;
    
    svg {
        height: 1rem;
        width: 1rem;
        margin-left: -.25rem;
        margin-right: .5rem;
        fill: $color-white-default;
    }

    &--small {
        @extend .caption-small;
        padding: .25rem .75rem;
    
        svg {
            height: .875rem;
            width: .875rem;
            margin-left: -.25rem;
            margin-right: .25rem;
        }
    }

    &--outline {
        background: transparent;
        border: solid .0625rem $color-black-20;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }
    }

    // Colors
    &--black {
        background: $color-black-default;
        color: $color-white-default;

        svg {
            fill: $color-white-default;
        }

        &#{$block}--outline {
            background: $color-black-10;
            border: solid .0625rem $color-black-20;
            color: $color-black-default;

            svg {
                fill: $color-black-default;
            }
        }
    }

    &--white {
        background: $color-white-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-white-10;
            border: solid .0625rem $color-white-20;
            color: $color-white-default;

            svg {
                fill: $color-white-default;
            }
        }
    }

    &--red {
        background: $color-red-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-red-20;
            border: solid .0625rem $color-red-20;
            color: $color-red-darker;

            svg {
                fill: $color-red-darker;
            }
        }
    }

    &--orange {
        background: $color-orange-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-orange-20;
            border: solid .0625rem $color-orange-20;
            color: $color-orange-darker;

            svg {
                fill: $color-orange-darker;
            }
        }
    }

    &--yellow {
        background: $color-yellow-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-yellow-20;
            border: solid .0625rem $color-yellow-20;
            color: $color-yellow-darker;

            svg {
                fill: $color-yellow-darker;
            }
        }
    }

    &--green {
        background: $color-green-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-green-20;
            border: solid .0625rem $color-green-20;
            color: $color-green-darker;

            svg {
                fill: $color-green-darker;
            }
        }
    }

    &--turquoise {
        background: $color-turquoise-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-turquoise-20;
            border: solid .0625rem $color-turquoise-20;
            color: $color-turquoise-darker;

            svg {
                fill: $color-turquoise-darker;
            }
        }
    }

    &--blue {
        background: $color-blue-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-blue-20;
            border: solid .0625rem $color-blue-20;
            color: $color-blue-darker;

            svg {
                fill: $color-blue-darker;
            }
        }
    }

    &--purple {
        background: $color-purple-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-purple-20;
            border: solid .0625rem $color-purple-20;
            color: $color-purple-darker;

            svg {
                fill: $color-purple-darker;
            }
        }
    }

    &--pink {
        background: $color-pink-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-pink-20;
            border: solid .0625rem $color-pink-20;
            color: $color-pink-darker;

            svg {
                fill: $color-pink-darker;
            }
        }
    }

    &--rose {
        background: $color-rose-default;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &#{$block}--outline {
            background: $color-rose-20;
            border: solid .0625rem $color-rose-20;
            color: $color-rose-darker;

            svg {
                fill: $color-rose-darker;
            }
        }
    }
}