.logo-slider {
    overflow: visible;

    .swiper-wrapper {
        margin: 0 -3rem;
    }
    
    .swiper-slide {
        width: auto;
        margin: 0 3rem;
        
        img {
            width: 6rem;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
    }
}