.steps {
    $block: &;
    text-align: center;

    &__header {
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }

        svg {
            display: none;
        }
    }

    &__row {
        position: relative;
        justify-content: center;
    }

    &__divider {
        border-left: solid .0625rem $color-black-10;
        height: 4rem;
        margin: 1.5rem 10rem;
        padding: 0;
        flex: 0 0 0px;

        @include media-breakpoint-up(md) {
            height: auto;
            margin: 0;
            position: absolute;
            top: 1.5rem;
            width: calc(33.333% - 8rem);
            border-left: none;
            border-bottom: solid .0625rem $color-black-10;

            &--one {
                left: calc(16.666% + 4rem);
            }
    
            &--two {
                left: calc(50% + 4rem);
            }
        }
        
        @include media-breakpoint-up(xl) {
            top: 2rem;
        }
    }

    &__count {
        @extend .title-700;
        @extend .title-light;
        color: $color-black-20;
    }

    &__text {
        margin-top: .5rem;
    }

    &__link {
        margin-top: 1rem;
    }

    &--icon {
        #{$block}__header {
            svg {
                display: inline-block;
                height: 3rem;
                width: 3rem;
                fill: $color-black-10;

                @include media-breakpoint-up(md) {
                    height: 4rem;
                    width: 4rem;
                }
            }
        }
        
        #{$block}__count {
            @extend .title-100;
            @extend .title-light;
            color: $color-black-20;
            margin-bottom: .5rem;
        }
        
        #{$block}__divider {
            @include media-breakpoint-up(md) {
                width: calc(33.333% - 4rem);
                top: .5625rem; // Half height of title
            }
            
            @include media-breakpoint-up(xl) {
                top: .625rem; // Half height of title
            }

            &--one {
                left: calc(16.666% + 2rem);
            }
    
            &--two {
                left: calc(50% + 2rem);
            }
        }
    }
}