.buttons {
    display: flex;
    flex-flow: row wrap;
    gap: .75rem .75rem;
    $block: &;

    .button {
        flex: 1 1 100%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
        }
    }

    &__primary, &__secondary {
        @extend .button;
        @extend .button--large;
    }

    &__secondary {
        @extend .button--outline
    }
}