.author {
    display: inline-flex;
    flex-flow: row wrap;
    background: $background-white;
    border-radius: $border-radius;
    border: solid .0625rem $color-black-10;
    box-shadow: $shadow-black-100;
    margin-top: 2rem;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    align-items: center;
    gap: 1rem;

    @include media-breakpoint-up(lg) {
        margin-top: 3rem;
    }

    &__avatar {
        object-fit: cover;
        flex: 0 0 3rem;
        width: 3rem;
        height: 3rem;
        border-radius: 2.5rem;
        background: $color-black-10;
    }

    &__wrapper {
        @extend .small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
        }
    }

    &__details {
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 0 .25rem;
        color: $color-black-60;
        margin-bottom: -.25rem;
    }

    &__name {
        font-weight: $font-weight-medium;
        color: $color-black-default;
    }

    &__reading-time {
        color: $color-yellow-darker;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        span {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            svg {
                width: 1rem;
                height: 1rem;
                fill: $color-yellow-darker;
            }
        }
    }
}