.header-author {
    $block: &;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 1.5rem;

    @include media-breakpoint-up(md) {
        gap: 2rem;
    }

    &__avatar {
        flex: 0 0 4rem;
        width: 4rem;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;

        @include media-breakpoint-up(md) {
            flex: 0 0 6rem;
            width: 6rem;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        flex: 1 1;
        padding-top: .75rem;
    }

    &__header {
        display: flex;
        flex: 1 1;
        align-items: flex-end;
        margin-bottom: 1.5rem;
    }

    &__socials {
        display: flex;
        margin-bottom: 1.5rem;
    }

    &__content {
        display: flex;
        flex: 1 1 100%;
    }

    &__button{
        margin-left: 10px;
    }

    &__title {
        flex: 1 1;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 0;
    }

    &__number-of-articles {
        @extend .title-400;
        @extend .title-light;
        color: $color-black-40;
        flex: 1 1 100%;
        margin-bottom: .25rem;
    }

    &__name {
        @extend .title-500;
        color: $color-black-default;
        flex: 1 1 100%;
    }
}
