﻿@mixin button-text {
    font-weight: $font-weight-medium;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -.01em;
}

@mixin button-text--small {
    font-size: .75rem;
    line-height: 1rem;
}

@mixin button-text--large {
    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 2rem;
    }
}