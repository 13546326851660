.content-quote {
    &__text {
        @extend .title-600;
        @extend .title-light;
    }

    &__footer {
        margin-top: 1.5rem;
    }

    &__author {
        @extend .title-200;
        @extend .title-medium;
        margin-bottom: 0;
    }

    &__caption {
        @extend .title-100;
        @extend .title-light;
        color: $color-black-60;
        margin-top: .25rem;
    }
}