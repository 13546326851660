.quote {
    $block: &;
    position: relative;
    background: $background-beige;
    border-radius: $border-radius;

    &__content {
        position: relative;
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include media-breakpoint-up(md) {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }

    svg {
        position: absolute;
        top: -1rem;
        height: 3rem;
        width: 3rem;
        overflow: visible;
        padding: .5rem;
        border-radius: 50%;
        background: $color-yellow-default;
        fill: $color-black-default;
    }

    &__text {
        color: $color-black-default;
    }

    &__caption {
        display: block;
        font-style: normal;
        color: $color-black-60;
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
        }
    }

    &--dark {
        background: $background-reassurance-green;

        #{$block}__text {
            color: $color-yellow-default;
        }
        
        #{$block}__caption {
            color: $color-white-60;
        }
    }

    &--yellow {
        background: $color-yellow-default;
    }

    &--turquoise {
        background: $color-turquoise-default;
    }

    &--purple {
        background: $color-purple-default;
    }

    &--orange {
        background: $color-orange-default;
    }
}

.block--light {
    .quote--default {
        background: $background-white;
    }
}