.footer-menu {
    &__title, &__list {
        flex: 0 0 100%;
    }

    &__title {
        @extend p;
        @extend strong;
        color: $color-yellow-default;
        margin-bottom: .25rem;
    }

    &__item {
        margin-bottom: .25rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        @include paragraph;
        color: $color-white-default;
        text-decoration: none;

        &:hover {
            color: $color-white-default;
            opacity: .5;
        }
    }
}