.form {
    $block: &;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: .75rem 0;

    &__row {
        display: flex;
        margin: 0 -.375rem;
        flex: 1 1 100%;
    }

    &__col-66 {
        flex: 1 1 66.666%;
        padding: 0 .375rem;
    }

    &__col-50 {
        flex: 1 1 50%;
        padding: 0 .375rem;
    }

    &__col-33 {
        flex: 1 1 33.333%;
        padding: 0 .375rem;
    }

    &__title {
        color: $color-black-default;
    }

    &__paragraph {
        margin-top: -.5rem;
        margin-bottom: 1.5rem;
    }

    &__submit {
        @extend .button;
        @extend .button--icon-right;
        @extend .button--large;
        @extend .button--yellow;
        flex: 1 1 100%;
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
        }
    }

    &--dark, &.dark {
        #{$block}__title {
            color: $color-yellow-default;
        }

        #{$block}__paragraph {
            color: $color-white-80;
        }
    }

    #{$block}--turquoise, #{$block}--purple, #{$block}--orange, #{$block}--yellow {
        #{$block}__submit {
            @extend .button--black;
        }
    }

    &.hidden {
        display: none;
    }
}

.form--light, .form--default {

    a{
        color: $background-leaf-green;
    }

}
