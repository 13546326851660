.careers {
    $block: &;
    @include block-padding;

    &__row {
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }
    }

    &__title {
        @extend .title-500;
        @extend .title-medium;
        margin-bottom: 1.5rem;
    }

    &__item {
        border-bottom: solid .0625rem $color-black-10;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__career {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 1.5rem 0;
        transition: $transition-default;

        &:hover {
            padding-right: .5rem;

            #{$block}__item-title {
                color: $color-black-60;
            }
        }
    }

    &__item-title {
        @extend .title-300;
        @extend .title-medium;
        flex: 1 1;
        transition: $transition-default;
    }
}
