.card-press {
    $block: &;
    display: block;
    background: $color-yellow-default;
    border-radius: $border-radius;
    aspect-ratio: 3/2;
    text-decoration: none;

    &:hover {
        text-decoration: none;

        #{$block}__content {
            background: $color-black-10;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding: 1.5rem;
        transition: $transition-default;
        border-radius: $border-radius;

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }
    }

    &__source {
        color: $color-black-60;
    }

    &__title {
        margin-top: .5rem;
        color: $color-black-default;
    }

    &__textlink {
        margin-top: 1rem;
    }

    &--dark {
        background: $background-reassurance-green;

        &:hover {    
            #{$block}__content {
                background: $color-white-10;
            }
        }
        
        #{$block}__source {
            color: $color-white-60;
        }

        #{$block}__title {
            color: $color-yellow-default;
        }
    }

    &--purple {
        background: $color-purple-default;
    }

    &--green {
        background: $background-growth-green;
    }

    &--turquoise {
        background: $color-turquoise-default;
    }

    &--orange {
        background: $color-orange-default;
    }
}