.button {
    // Default button is black and solid
    @include button-text;
    border: none;
    box-sizing: border-box;
    padding: .75rem 1.5rem;
    border-radius: 2rem;
    background: $color-black-default;
    color: $color-white-default;
    transition: $transition-default;
    text-align: center;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    $block: &;

    &:hover {
        background: $color-black-80;
        color: $color-white-default;
        text-decoration: none;
        outline: none;
    }

    span {
        flex-grow: 1;
        text-align: center;
    }

    svg {
        transition: $transition-default;
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-white-default;
    }

    &--icon-left {
        svg { 
            margin-left: -.5rem;
            margin-right: .75rem
        }
    }

    &--icon-right {        
        svg { 
            order: 2;
            margin-right: -.5rem;
            margin-left: .75rem
        }
    }

    &--icon {
        padding: .75rem;

        span {
            display: none;
        }
    }

    &--large {
        @include button-text--large;
        padding: .75rem 1.5rem;

        svg {
            overflow: visible;
            width: 1.5rem;
            height: 1.5rem;
            padding: .25rem;
            border-radius: 1rem;
            background: $color-white-default;
            fill: $color-black-default;
        }

        &#{$block}--icon-left {
            svg { 
                margin-left: -.5rem;
                margin-right: .75rem;
            }
        }

        &#{$block}--icon-right {
            svg { 
                margin-right: -.5rem;
                margin-left: .75rem;
            }
        }

        &#{$block}--icon {
            padding: .75rem;

            svg {
                background: transparent;
                fill: $color-white-default;
            }
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 2rem;

            svg {
                width: 2rem;
                height: 2rem;
                padding: .375rem;
            }

            &#{$block}--icon-left {
                svg { 
                    margin-left: -1rem;
                    margin-right: 1rem;
                }
            }

            &#{$block}--icon-right {
                svg { 
                    margin-right: -1rem;
                    margin-left: 1rem;
                }
            }

            &#{$block}--icon {
                padding: 1rem;

                svg {
                    background: transparent;
                    fill: $color-white-default;
                }
            }
        }
    }

    &--small {
        @include button-text--small;
        padding: .5rem 1rem;

        svg {
            width: 1rem;
            height: 1rem;
        }

        &#{$block}--icon-left {
            svg { 
                margin-left: -.5rem;
                margin-right: .5rem;
            }
        }

        &#{$block}--icon-right {
            svg { 
                margin-right: -.5rem;
                margin-left: .5rem;
            }
        }

        &#{$block}--icon {
            padding: .5rem;
        }
    }

    // Disabled
    &:disabled, &.disabled {
        opacity: .25;
        pointer-events: none;
    }

    // Button styles
    &--outline, &--transparent {
        background: transparent;
        color: $color-black-default;

        svg {
            fill: $color-black-default;
        }

        &:hover {
            background: $color-black-10;
            color: $color-black-80;

            svg {
                fill: $color-black-80;
            }
        }
        
        &#{$block}--large {
            svg {
                background: $color-black-default;
                fill: $color-white-default;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-black-default;
                }
            }
        }
    }

    &--outline {
        border: .0625rem solid $color-black-default;
    }

    // Colors
    &--white {
        background: $color-white-default;
        color: $color-black-default;
        border-color: $color-white-default;

        svg {
            fill: $color-black-default;
        }

        &:hover {
            background: $color-white-80;
            color: $color-black-default;
        }

        &#{$block}--large {
            svg {
                background: $color-black-default;
                fill: $color-white-default;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-black-default;
                }
            }
        }

        &#{$block}--outline, &#{$block}--transparent {
            background: transparent;
            color: $color-white-default;

            svg {
                fill: $color-white-default;
            }

            &:hover {
                background: $color-white-10;
                color: $color-white-80;

                svg {
                    fill: $color-white-80;
                }
            }

            &#{$block}--large {
                svg {
                    background: $color-white-default;
                    fill: $color-black-default;
                }
    
                &#{$block}--icon {
                    svg {
                        background: transparent;
                        fill: $color-white-default;
                    }
                }
            }
        }

        &#{$block}--outline {
            border-color: $color-white-default;
        }
    }
    
    &--black {
        background: $color-black-default;
        color: $color-white-default;
        border-color: $color-black-default;

        svg {
            fill: $color-white-default;
        }

        &:hover {
            background: $color-black-80;
            color: $color-white-default;
        }

        &#{$block}--large {
            svg {
                background: $color-white-default;
                fill: $color-black-default;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-white-default;
                }
            }
        }

        &#{$block}--outline, &#{$block}--transparent {
            background: transparent;
            color: $color-black-default;

            svg {
                fill: $color-black-default;
            }

            &:hover {
                background: $color-black-10;
                color: $color-black-80;

                svg {
                    fill: $color-black-80;
                }
            }

            &#{$block}--large {
                svg {
                    background: $color-black-default;
                    fill: $color-white-default;
                }
    
                &#{$block}--icon {
                    svg {
                        background: transparent;
                        fill: $color-black-default;
                    }
                }
            }
        }

        &#{$block}--outline {
            border-color: $color-black-default;
        }
    }

    &--yellow {
        background: $color-yellow-default;
        color: $color-black-default;
        border-color: $color-yellow-default;

        svg {
            fill: $color-black-default;
        }

        &:hover {
            background: $color-yellow-darker;
            color: $color-black-default;
        }

        &#{$block}--large {
            svg {
                background: $color-black-default;
                fill: $color-white-default;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-black-default;
                }
            }
        }
    }

    &--red {
        background: $color-red-default;
        color: $color-white-default;
        border-color: $color-red-default;

        svg {
            fill: $color-white-default;
        }

        &:hover {
            background: $color-red-80;
            color: $color-white-default;
        }

        &#{$block}--large {
            svg {
                background: $color-white-default;
                fill: $color-red-darker;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-white-default;
                }
            }
        }

        &#{$block}--outline, &#{$block}--transparent {
            background: transparent;
            color: $color-red-darker;

            svg {
                fill: $color-red-darker;
            }

            &:hover {
                background: $color-red-20;
                color: $color-red-darker;

                svg {
                    fill: $color-red-darker;
                }
            }

            &#{$block}--large {
                svg {
                    background: $color-red-default;
                    fill: $color-white-default;
                }
    
                &#{$block}--icon {
                    svg {
                        background: transparent;
                        fill: $color-red-darker;
                    }
                }
            }
        }

        &#{$block}--outline {
            border-color: $color-red-default;
        }
    }

    &--green {
        background: $color-green-default;
        color: $color-white-default;
        border-color: $color-green-default;

        svg {
            fill: $color-white-default;
        }

        &:hover {
            background: $color-green-80;
            color: $color-white-default;
        }

        &#{$block}--large {
            svg {
                background: $color-white-default;
                fill: $color-green-darker;
            }

            &#{$block}--icon {
                svg {
                    background: transparent;
                    fill: $color-white-default;
                }
            }
        }

        &#{$block}--outline, &#{$block}--transparent {
            background: transparent;
            color: $color-green-darker;

            svg {
                fill: $color-green-darker;
            }

            &:hover {
                background: $color-green-20;
                color: $color-green-darker;

                svg {
                    fill: $color-green-darker;
                }
            }

            &#{$block}--large {
                svg {
                    background: $color-green-default;
                    fill: $color-white-default;
                }
    
                &#{$block}--icon {
                    svg {
                        background: transparent;
                        fill: $color-green-darker;
                    }
                }
            }
        }

        &#{$block}--outline {
            border-color: $color-green-default;
        }
    }
}
