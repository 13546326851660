.lead, p, small, .small, .extrasmall {
    font-weight: $font-weight-light;
    color: $color-black-80;
    margin-bottom: 1rem;
    letter-spacing: -.02em;

    &:last-child:not(.form__paragraph) {
        margin-bottom: 0;
    }
}

p {
    @include paragraph;
}

.lead {
    @include paragraph-lead;
}

small, .small {
    @include paragraph-small;
}

.extrasmall {
    font-size: .75rem;
    line-height: 1rem;
}

b, strong {
    font-weight: $font-weight-medium;
}
