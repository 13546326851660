.card-type {
    $block: &;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1rem .5rem 1rem 1.5rem;
    text-decoration: none;
    border-radius: $border-radius;
    color: $color-black-default;

    &:hover {
        text-decoration: none;
    }

    &__title {
        margin-right: .5rem;
        flex: 1 1;
    }

    &--dark {
        background: $background-reassurance-green;

        #{$block}__title {
            color: $color-white-default;
        }

        #{$block}__button {
            @extend .button--white;
        }

        &:hover {
            background: rgba($background-reassurance-green, .8)
        }
    }

    &--yellow {
        background: $color-yellow-default;

        &:hover {
            background: $color-yellow-darker;
        }
    }

    &--orange {
        background: $color-orange-default;

        &:hover {
            background: $color-orange-darker;
        }
    }

    &--turquoise {
        background: $color-turquoise-default;

        &:hover {
            background: $color-turquoise-darker;
        }
    }

    &--purple {
        background: $color-purple-default;

        &:hover {
            background: $color-purple-darker;
        }
    }

    &--green {
        background: $background-growth-green;

        &:hover {
            background: $background-leaf-green;
        }
    }
}