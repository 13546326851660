.header-resources, .header-type, .header-detail, .header-article {
    $block: &;
    position: relative;

    &__wrapper {
        position: relative;
    }

    &__line {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 75vw;
        height: auto;
    }

    &__types {
        display: flex;
        flex-flow: row wrap;
        gap: .5rem .5rem;

        .card-type {
            flex: 1 1 100%;
            max-width: 100%;

            @include media-breakpoint-up(sm) {
                flex: 0 0 calc(50% - .5rem);
                max-width: calc(50% - .5rem);
            }

            @include media-breakpoint-up(lg) {
                flex: 1 1;
            }
        }
    }

    &__content {
        position: relative;
    }

    &__back {
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }
    }

    &__parent {
        color: $color-black-40;
        margin-bottom: .25rem;
    }

    &__title, &__date {
        color: $color-black-default;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }

    &__date {
        display: block;
        color: $color-black-60;

        // Cancel margin-bottom from title
        margin-top: -1.25rem;

        @include media-breakpoint-up(lg) {
            margin-top: -1.75rem;
        }
    }

    &__image {
        margin-top: 2rem;
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius;
        
        @include media-breakpoint-up(md) {
            margin-top: 3rem;
            aspect-ratio: 21/9;
        }
        
        @include media-breakpoint-up(xl) {
            margin-top: 4rem;
        }
    }
}

.header-article {
    &__title {
        margin-bottom: 1rem;
    }
}