.video-fullscreen {
    $block: &;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    aspect-ratio: 1/1;

    @include media-breakpoint-up(sm) {
        aspect-ratio: 16/9;
    }

    @include media-breakpoint-up(md) {
        aspect-ratio: 21/9;
    }

    .plyr {
        @include position-absolute;

        &__controls {
            display: none;
        }

        &__poster {
            background-size: cover;
        }

        &--full-ui {
            .plyr__control--overlaid {
                display: none;
            }
        }

        &:fullscreen, &.played {
            .plyr__controls, .plyr__control--overlaid {
                display: flex;
            }

            & + .video-fullscreen__overlay {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__overlay {
        min-height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: $transition-default;
        background: rgba($background-reassurance-green, .3);
    }

    &__circle {
        position: absolute;
        width: 125%;
        aspect-ratio: 1/1;
        left: 0;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        &--one {
            background: $gradient-circle-yellow;
            opacity: .6;
            top: 0;
        }

        &--two {
            background: $gradient-circle-dark;
            top: 100%;
        }
    }

    &__content {
        position: relative;
    }

    &__title {
        color: $color-white-default;
        margin-bottom: .5rem;
    }

    &__subtitle {
        color: $color-white-80;
    }

    &__button {
        margin-top: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
        }
    }
}