.error-page {
    $block: &;
    position: relative;
    padding: 1rem;

    @include media-breakpoint-up(xl) {
        padding: 3rem;
    }

    &__wrapper {
        @include block-padding--large;
        background: $background-beige;
        border-radius: $border-radius;
        position: relative;
        overflow: hidden;
    }

    &__circle {
        position: absolute;
        top: 50%;
        left: 100%;
        width: 100vw;
        height: 100vw;
        background: $gradient-circle-green;
        opacity: .6;
        transform: translate(-50%, -50%);
    }

    &__content {
        position: relative;
        text-align: center;
    }

    svg {
        fill: $background-growth-green;
        margin-bottom: 2rem;
        width: 4rem;
        height: 4rem;

        @include media-breakpoint-up(xl) {
            width: 6rem;
            height: 6rem;
        }
    }

    &__title {
        @extend .title-700;
        margin-bottom: 1rem;
    }

    &__paragraph {
        @extend .lead;
    }
}