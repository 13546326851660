.location {
    $block: &;
    border-radius: $border-radius;
    background: $background-beige;
    overflow: hidden;
    position: relative;

    &__image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius $border-radius 0 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            height: 100%;
            width: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 50%;
            border-radius: $border-radius 0 0 $border-radius;
        }
    }

    &__row {
        justify-content: flex-end;
    }

    &__content {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }

    &__title {
        @extend .title-300;
        @extend .title-light;
        color: $color-black-60;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;
        }
    }

    &__title-prefix {
        @extend .title-bold;
        color: $color-black-default;
        padding-right: .5rem;
    }

    &__buttons {
        margin-top: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
        }
    }
}

.block--light {
    .location {
        background: $background-white;
    }
}