.cta {
    $block: &;
    @include block-padding;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    &__circle {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vw;
        opacity: .4;
    }

    &__content {
        position: relative;
    }

    &__title {
        margin-bottom: .5rem;
    }

    &__buttons {
        margin-top: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
        }
    }

    &--dark {
        background: $background-reassurance-green;

        #{$block}__circle {
            background: $gradient-circle-yellow;
            opacity: .2;
        }

        #{$block}__title {
            color: $color-yellow-default;
        }
        
        #{$block}__subtitle {
            color: $color-white-default;
        }
    }

    &--yellow {
        background: $color-yellow-default;

        #{$block}__circle {
            background: $gradient-circle-turquoise;
        }
    }

    &--turquoise {
        background: $color-turquoise-default;

        #{$block}__circle {
            background: $gradient-circle-purple;
        }
    }

    &--purple {
        background: $color-purple-default;

        #{$block}__circle {
            background: $gradient-circle-orange;
        }
    }

    &--orange {
        background: $color-orange-default;

        #{$block}__circle {
            background: $gradient-circle-yellow;
        }
    }
}