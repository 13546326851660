*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    font-size: 16px;

    @include media-breakpoint-up(xxxl) {
        font-size: 17px;
    }

    @include media-breakpoint-up(xxxxl) {
        font-size: 19px;
    }
}

html, body {
    margin: 0 auto;
    padding: 0;
    height: 100%;
    font-family: $font-family-base;
    background-color: $background-white;
}

main {
    overflow: clips;
    width: 100%;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

::selection {
    background-color: $color-yellow-40;
}