.article {
    $block: &;
    @include block-padding;

    &__row {
        justify-content: center;
    }

    &--left {
        #{$block}__row {
            justify-content: flex-start;
        }
        .content-image, .content-video, .content-quote {
            @include media-breakpoint-up(sm) {
                margin-left: 0;
                margin-right: -16.666%;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 0;
                margin-right: -30%;
            }
        }
    }

    .content-image, .content-video, .content-quote {
        @include media-breakpoint-up(sm) {
            margin-left: -8.333%;
            margin-right: -8.333%;
        }

        @include media-breakpoint-up(lg) {
            margin-left: -15%;
            margin-right: -15%;
        }
    }

    .content-image, .content-video {
        @include media-breakpoint-up(sm) {
            width: 116.666%;
        }

        @include media-breakpoint-up(lg) {
            width: 130%;
        }
    }
}
