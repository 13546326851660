.article-blog {
    $block: &;
    @include block-padding;

    &__row {
        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    &__table-of-contents {
        position: sticky;
        top: calc($navbar-height + 2rem);
        margin-bottom: 3rem;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}
