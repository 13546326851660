// Shadows
$elevation-100: 0 .125rem .5rem -.125rem rgba($color-black-default, 0.15);
$elevation-200: 0 .1875rem .75rem -.1875rem rgba($color-black-default, 0.15);
$elevation-300: 0 .25rem 1rem -.25rem rgba($color-black-default, 0.15);
$elevation-400: 0 .375rem 1.5rem -.375rem rgba($color-black-default, 0.15);
$elevation-500: 0 .5rem 1rem -.5rem rgba($color-black-default, 0.15);
$elevation-600: 0 .75rem 3rem -.75rem rgba($color-black-default, 0.15);
$elevation-700: 0 1rem 4rem -1rem rgba($color-black-default, 0.15);

$shadow-black-100: 0 .125rem 0 0 rgba($color-black-default, 0.05);
$shadow-black-200: 0 .25rem 0 0 rgba($color-black-default, 0.05);
$shadow-black-300: 0 .375rem 0 0 rgba($color-black-default, 0.05);
$shadow-black-400: .25rem .25rem 0 0 rgba($color-black-default, 0.05);

$shadow-white-100: 0 .125rem 0 0 rgba($color-white-default, 0.05);
$shadow-white-200: 0 .25rem 0 0 rgba($color-white-default, 0.05);
$shadow-white-300: 0 .375rem 0 0 rgba($color-white-default, 0.05);
$shadow-white-400: .25rem .25rem 0 0 rgba($color-white-default, 0.05);