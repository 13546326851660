.footer {
    $block: &;
    overflow: hidden;
    background: $background-reassurance-green;
    border-top: solid .0625rem $color-white-10;

    &__content {
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-up(md) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
    }

    &__row {
        justify-content: center;
        gap: 3rem 0;
    }

    &__menu-row {
        gap: 3rem 0;
    }

    &__left {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        gap: 1rem 1.5rem;

        @include media-breakpoint-up(md) {
            gap: 2rem 1.5rem;
        }
    }

    &__logo {
        flex: 0 0 auto;

        svg {
            height: 2rem;
            width: auto;
            fill: $color-yellow-default;
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                height: 3rem;
            }
        }

        &:hover {
            svg {
                fill: $color-yellow-40;
            }
        }

        span {
            display: none;
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        gap: .5rem .5rem;

        @include media-breakpoint-up(md) {
            flex: 1 1 100%;
        }

        span {
            font-weight: $font-weight-light;
            color: $color-white-80;
            margin-right: .25rem;
        }
    }

    &__privacy {
        flex: 1 1 100%;

        @include media-breakpoint-up(sm) {
            flex: 1 1;
        }
    }

    &__button {
        @extend .button;
        @extend .button--yellow;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }
}
