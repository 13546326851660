a {
    img {
        transition: $transition-default;
    }
    
    &:hover {
        img {
            opacity: .5;
        }
    }
}