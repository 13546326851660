.textlink {
    // Default textlink is black
    @include button-text;
    display: inline-block;
    text-decoration: none;
    color: $color-black-default;
    border-bottom: .0625rem solid $color-black-40;
    transition: $transition-default;

    &:hover {
        color: $color-black-60;
        text-decoration: none;
        border-bottom: .0625rem solid $color-black-60;
    }

    &--large {
        @include button-text--large;
    }

    &--small {
        @include button-text--small;
    }

    // Disabled
    &:disabled, &.disabled {
        opacity: .25;
        pointer-events: none;
    }

    // Colors
    &--white {
        color: $color-white-default;
        border-color: $color-white-40;

        &:hover {
            color: $color-white-60;
            border-color: $color-white-60;
        }
    }
    
    &--yellow {
        color: $color-yellow-default;
        border-color: $color-yellow-40;

        &:hover {
            color: $color-yellow-60;
            border-color: $color-yellow-60;
        }
    }
    
    &--red {
        color: $color-red-default;
        border-color: $color-white-40;

        &:hover {
            color: $color-red-60;
            border-color: $color-white-60;
        }
    }
}