.table-of-contents {
    background: $background-white;
    border-radius: $border-radius;
    border: solid .0625rem $color-black-10;
    box-shadow: $shadow-black-100;

    &__header {
        @extend .title-100;
        @extend .title-light;
        color: $color-black-40;
        padding: 1.5rem 1.5rem 0;
    }

    &__list {
        padding: 1rem 0;
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        flex: 1 1 100%;
    }

    &__link {
        display: block;
        text-decoration: none;
        padding: .5rem 1.5rem;
        color: $color-black-default;
        transition: $transition-default;

        &:hover {
            background: $color-black-05;
            color: $color-black-60;
        }
    }

    &__h2 {
        @extend .title-100;
        @extend .title-medium;
    }

    &__h3,&__h4 {
        @extend .title-100;
        @extend .title-light;
        color: $color-black-60;
    }
}