.form-floating {
  flex: 1 1 100%;

  // Typography
  .form-control,
  .form-select,
  > label {
    font-weight: $font-weight-light;
    letter-spacing: -0.02em;
    font-size: 1rem;
    line-height: 1.5;
    transition: $transition-short;

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  // Sizing
  > .form-control,
  > .form-select {
    border-radius: $border-radius;
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    padding-top: 1.5rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-up(lg) {
      height: calc(4rem + calc(var(--bs-border-width) * 2));
      min-height: calc(4rem + calc(var(--bs-border-width) * 2));
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &:focus,
    &:not(:placeholder-shown) {
      ~ {
        label {
          transform: scale(0.75) translateY(-0.5rem) translateX(0.375rem);

          @include media-breakpoint-up(lg) {
            transform: scale(0.75) translateY(-0.5rem) translateX(0.5rem);
          }
        }
      }
    }
  }

  > textarea.form-control {
    height: calc(7.5rem + calc(var(--bs-border-width) * 2));

    @include media-breakpoint-up(lg) {
      height: calc(8rem + calc(var(--bs-border-width) * 2));
    }
  }

  > label {
    padding: 1rem 1rem;

    @include media-breakpoint-up(lg) {
      padding: 1rem 1.5rem;
    }
  }

  // Colouring
  > .form-control,
  > .form-select {
    color: $color-black-80;
    background-color: transparent;
    border-color: $color-black-20;
    box-shadow: $shadow-black-100;

    &:hover {
      box-shadow: $shadow-black-200;
      border-color: $color-black-40;
    }

    &:focus {
      box-shadow: $shadow-black-300, 0 0 0 0.25rem $color-yellow-20;
    }

    &:focus,
    &:not(:placeholder-shown) {
      ~ {
        label {
          color: $color-black-40;

          &::after {
            background: none;
          }
        }
      }
    }

    ~ label {
      color: $color-black-40;

      &::after {
        background: none;
      }
    }
  }

  > .form-select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%230B0B0A' stroke-opacity='.6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%230B0B0A' stroke-opacity='1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    }
  }
}

.form--dark {
  .form-floating {
    > .form-control,
    > .form-select {
      color: $color-white-80;
      border-color: $color-white-20;

      &:hover {
        box-shadow: $shadow-white-200;
        border-color: $color-white-40;
      }

      &:focus {
        box-shadow: $shadow-white-300, 0 0 0 0.25rem $color-yellow-20;
      }

      &:focus,
      &:not(:placeholder-shown) {
        ~ {
          label {
            color: $color-white-40;
          }
        }
      }

      ~ label {
        color: $color-white-40;
      }
    }

    > .form-select {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23FAFAF9' stroke-opacity='.8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");

      &:hover,
      &:focus {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23FAFAF9' stroke-opacity='1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
      }
    }
  }
}
