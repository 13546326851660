.credits {
    display: flex;
    padding: 1.5rem 2rem 1.5rem 1.5rem;
    flex-flow: row wrap;
    gap: 1rem;
    background: $background-beige;
    border-radius: $border-radius;
    border: solid .0625rem $color-black-10;
    box-shadow: $shadow-black-100;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        padding: 2rem 3rem 2rem 2rem;
        gap: 1.5rem;
        margin-top: 3rem;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 4rem;
    }

    &__header {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: .25rem;
        flex: 1 1;
    }

    &__written-by {
        @extend .title-50;
        @extend .title-light;
        color: $color-black-40;
        flex: 1 1 100%;
    }

    &__name {
        @extend .title-200;
        flex: 1 1 100%;
    }

    &__name a {
        color: $color-black-default;
        text-decoration: none;

        &:hover{
            color: $color-black-60;
        }
    }

    &__avatar {
        object-fit: cover;
        flex: 0 0 3rem;
        width: 3rem;
        height: 3rem;
        border-radius: 2.5rem;
        background: $color-black-10;

        @include media-breakpoint-up(md) {
            flex: 0 0 4.5rem;
            width: 4.5rem;
            height: 4.5rem;
        }
    }
}
