.block {
    @include block-padding;
    $block: &;
    position: relative;

    &--small {
        @include block-padding--small;

        &.stackable {
            padding-top: 0;
            padding-bottom: 0;

            @include media-breakpoint-up(md) {
                padding-top: 0;
                padding-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 0;
                padding-bottom: 0;
            }

            &--first {
                padding-top: 2rem;

                @include media-breakpoint-up(md) {
                    padding-top: 3rem;
                }
            }

            &--last {
                padding-bottom: 2rem;

                @include media-breakpoint-up(md) {
                    padding-bottom: 3rem;
                }
            }
        }

        &:last-of-type {
            padding-bottom: 3rem;

            @include media-breakpoint-up(md) {
                padding-bottom: 4rem;
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 6rem;
            }
        }
    }

    &--boxed {
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(xl) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &--default {
        &+& {
            padding-top: 0;
        }

        &.block--small + & {
            padding-top: 1rem;

            @include media-breakpoint-up(md) {
                padding-top: 1rem;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 3rem;
            }
        }

        &.block--small + &.block--small {
            padding-top: 0;
        }
    }

    &--light {
        background: $background-beige;

        &+& {
            padding-top: 0;
        }

        &.block--small + & {
            padding-top: 1rem;

            @include media-breakpoint-up(md) {
                padding-top: 1rem;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 3rem;
            }
        }

        &.block--small + &.block--small {
            padding-top: 0;
        }
    }
}