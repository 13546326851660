.card-event {
    &__image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }

    &__date {
        color: $color-black-60;
        margin-bottom: .25rem;
    }

    &__intro {
        margin-top: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 3rem;

        @include media-breakpoint-up(lg) {
            height: 4rem;
        }
    }

    &__textlink {
        margin-top: 1rem;
    }
}