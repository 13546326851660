.two-columns {
    $block: &;
    overflow: hidden;
    position: relative;

    &__container {
        position: static;
    }

    &__row {
        justify-content: space-between;
    }

    &__content {
        @include block-padding;
        position: relative;
    }

    &__background {
        position: absolute;
        height: 100%;
    }

    &__column-one, &__column-two {
        #{$block}__background {
            left:0;
            width: 100%;
        }
    }

    &__column-one {
        #{$block}__background {
            @include media-breakpoint-up(md) {
                width:50%;
                left: 0;
                right: 50%;
            }
        }
    }

    &__column-two {
        #{$block}__background {
            @include media-breakpoint-up(md) {
                width:50%;
                left: 50%;
                right: 0;
            }
        }
    }

    &--dark {
        #{$block}__background {
            background: $background-reassurance-green;
        }
    }

    &--light {
        #{$block}__background {
            background: $background-beige;
        }
    }

    &--yellow {
        #{$block}__background {
            background: $color-yellow-default;
        }
    }

    &--orange {
        #{$block}__background {
            background: $color-orange-default;
        }
    }

    &--turquoise {
        #{$block}__background {
            background: $color-turquoise-default;
        }
    }

    &--purple {
        #{$block}__background {
            background: $color-purple-default;
        }
    }
}

.block--light {
    .two-columns--light {
        &__background {
            background: $background-beige;
        }
    }
}

.stackable {
    $stackable: &;

    &--first {
        .two-columns {
            border-radius: $border-radius $border-radius 0 0;
        }
    }

    &--last {
        .two-columns {
            border-radius: 0 0 $border-radius $border-radius;
        }

        &#{$stackable}--first {
            .two-columns {
                border-radius: $border-radius;
            }
        }
    }
}