.overview {
    $block: &;

    &__row {
        justify-content: center;
        gap: $grid-gutter-width 0;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }
    }
}