.contact {
    @include block-padding--large;
    $block: &;
    position: relative;
    background: $background-reassurance-green;

    &__circle {
        position: absolute;
        top: 50%;
        width: 100vw;
        height: 100vw;
        top: 50%;
        right: 75%;
        transform: translateY(-50%);
        background: $gradient-circle-yellow;
        opacity: .2;
    }

    &__line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75vw;
        height: 75vw;
    }

    &__row {
        justify-content: center;
    }

    &__content {
        @include block-padding--small--bottom;
        text-align: center;
    }

    &__title {
        color: $color-yellow-default;
        margin-bottom: .5rem;
    }

    &__body, &__body p, &__body a {
        color: $color-white-80;
    }

    &__paragraph, &__paragraph a {
        color: $color-white-80;
    }

    &__form {
        justify-content: center;
    }
}
