// Title basics
.title-50, .title-100, .title-200, .title-300, .title-400, .title-500, .title-600, .title-700, .title-800, .title-900 {
    color: $color-black-default;
    line-height: 100%;
    margin: 0;
    font-family: $font-family-base;

    // Title default bold
    font-weight: $font-weight-bold;
}

// Title styles
.title-bold {
    font-weight: $font-weight-bold;
}

.title-medium {
    font-weight: $font-weight-medium;
    letter-spacing: -.02em;
}

.title-light {
    font-weight: $font-weight-light;
    letter-spacing: -.03em;
}

// Title sizes
.title-50 {
    font-size: .875rem;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
    }
}
.title-100 {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
    }
}

.title-200 {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
    }
}

.title-300 {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.75rem;
    }
}

.title-400 {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2rem;
    }
}

.title-500 {
    font-size: 1.75rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2.5rem;
    }
}

.title-600 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3rem;
    }
}

.title-700 {
    font-size: 2.5rem;

    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 4rem;
    }
}

.title-800 {
    font-size: 3rem;

    @include media-breakpoint-up(md) {
        font-size: 3.5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 5rem;
    }
}

.title-900 {
    font-size: 4rem;

    @include media-breakpoint-up(md) {
        font-size: 6rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 7.5rem;
    }
}