.testimonial {
    $block: &;
    position: relative;
    padding: 2rem;
    background: $background-beige;
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
        padding: 3rem;
    }

    &__text {
        @extend .title-500;
        @extend .title-light;
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
    }

    &__person {
        flex: 1 1;
    }

    &__name {
        @extend p;
        @extend strong;
        margin-bottom: 0;
        color: $color-black-default;
    }

    &__position {
        @extend small;
        margin-bottom: 0;
        color: $color-black-60;
    }

    &__image {
        flex: 0 0 2.5rem;
        max-width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
        border-radius: 50%;

        @include media-breakpoint-up(md) {
            flex: 0 0 3rem;
            max-width: 3rem;
            height: 3rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 4rem;
            max-width: 4rem;
            height: 4rem;
        }
    }
}

.block--light {
    .testimonial {
        background: $background-white;
    }
}