.highlight-story {
    &__client {
        color: $color-black-60;
    }

    &__title {
        margin-top: .5rem;
    }

    &__textlink {
        margin-top: 1.5rem;
    }
}