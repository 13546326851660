.content-text {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.content-text + .content-text {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        margin-top: 3rem;
    }
}
