.featured-slider {
    &__header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 1rem 2rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
    }

    &__title {
        @extend .title-500;
        flex: 1 1 100%;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            flex: 1 1;
        }
    }

    &__controls {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        gap: .5rem .5rem;
    }

    &__slider {
        overflow: visible;
    }

    &__row {
        flex-wrap: nowrap;
        width: calc(100% + $grid-gutter-width);
    }
}