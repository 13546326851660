.accordion {
    $block: &;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-bottom: solid .0625rem $color-black-10;
    padding: .5rem 0;

    @include media-breakpoint-up(md) {
        padding: .75rem 0;
    }

    @include media-breakpoint-up(xl) {
        padding: 1rem 0;
    }

    &:last-of-type {
        border-bottom: none;
    }

    &__title {
        flex: 1 1;
        cursor: pointer;
    }

    &__content {
        flex: 1 1 100%;
        display: none;
        padding: 1rem 0;
    }

    &__minus, &__plus {
        transition: $transition-default;
    }

    &__plus {
        position: absolute;
        transform: rotate(90deg);
    }

    &.active {
        #{$block}__content {
            display: block;
        }

        #{$block}__minus {
            transform: rotate(180deg);
        }

        #{$block}__plus {
            transform: rotate(360deg);
        }
    }
}