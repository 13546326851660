.highlight-article {
    &__caption {
        margin-top: .5rem;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        flex-grow: 1;
    }

    &__date, &__reading-time {
        @extend small;
        color: $color-black-40;
        margin-bottom: 0;
    }

    &__divider {
        height: 1.5rem;
        border-left: .0625rem solid $color-black-40;
        margin: 0 1rem;
    }

    &__intro {
        margin-top: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 3rem;

        @include media-breakpoint-up(lg) {
            height: 4rem;
        }
    }

    &__textlink {
        margin-top: 1.5rem;
    }
}