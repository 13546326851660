﻿@mixin block-padding--top {
    padding-top: 3rem;

    @include media-breakpoint-up(md) {
        padding-top: 4rem;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 6rem;
    }
}

@mixin block-padding--bottom {
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 6rem;
    }
}

@mixin block-padding {
    @include block-padding--top;
    @include block-padding--bottom;
}

@mixin block-padding--small--top {
    padding-top: 2rem;

    @include media-breakpoint-up(md) {
        padding-top: 3rem;
    }
}

@mixin block-padding--small--bottom {
    padding-bottom: 2rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 3rem;
    }
}

@mixin block-padding--small {
    @include block-padding--small--top;
    @include block-padding--small--bottom;
}

@mixin block-padding--large--top {
    padding-top: 4rem;

    @include media-breakpoint-up(md) {
        padding-top: 6rem;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 8rem;
    }
}

@mixin block-padding--large--bottom {
    padding-bottom: 4rem;

    @include media-breakpoint-up(md) {
        padding-bottom: 6rem;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 8rem;
    }
}

@mixin block-padding--large {
    @include block-padding--large--top;
    @include block-padding--large--bottom;
}