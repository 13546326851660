:root {
    --bs-dropdown-spacer: 1rem;
}

.dropdown-menu {
    border: none;
    box-shadow: $elevation-400;

    &.show {
        margin-top: 1rem;
    }
}

.dropdown-item {
    padding: .375rem 1rem;
    font-weight: $font-weight-light;

    &.parent {
        font-weight: $font-weight-medium;
    }
}

.dropdown-toggle {
    &::after{
        margin-left: 0.5rem;
        vertical-align: 0.175em;
    }
}