a {
    text-decoration: underline;
    transition: $transition-default;
    color: $color-yellow-default;

    &:hover {
        text-decoration: none;
        color: $color-yellow-default;
    }
}

.caption {
    font-weight: $font-weight-medium;
    font-size: .75rem;
    line-height: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.caption-small {
    font-weight: $font-weight-medium;
    font-size: .625rem;
    line-height: .875rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}