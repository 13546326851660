.proof {
    $block: &;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-flow: row wrap;

    &__image, &__text { 
        flex: 0 0 100%;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            width: 50%;
        }
    }

    &__image {
        aspect-ratio: 21/9;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            min-height: 100%;
        }
    }

    &__text {
        @include block-padding;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        margin-bottom: .5rem;
    }

    // Flipped
    &--flipped {
        flex-direction: row-reverse;
    }

    // Colours
    &--light {
        background: $background-beige;
    }
    
    &--dark {
        background: $background-reassurance-green;

        #{$block}__title {
            color: $color-yellow-default;
        }
        
        #{$block}__subtitle {
            color: $color-white-default;
        }
    }
    
    &--yellow {
        background: $color-yellow-default;
    }
    
    &--turquoise {
        background: $color-turquoise-default;
    }
    
    &--orange {
        background: $color-orange-default;
    }
    
    &--purple {
        background: $color-purple-default;
    }
}

.block--light {
    .proof--light {
        background: $background-white;
    }
}

.stackable {
    $stackable: &;

    &--first {
        .proof {
            border-radius: $border-radius $border-radius 0 0;

            &__image {
                border-radius: $border-radius 0 0 0;
            }

            &--flipped {
                .proof__image {
                    border-radius: 0 $border-radius 0 0;
                }
            }
        }
    }

    &--last {
        .proof {
            border-radius: 0 0 $border-radius $border-radius;

            &__image {
                border-radius: 0 0 0 $border-radius;
            }

            &--flipped {
                .proof__image {
                    border-radius: 0 0 $border-radius 0;
                }
            }
        }

        &#{$stackable}--first {
            .proof {
                border-radius: $border-radius;
    
                &__image {
                    border-radius: $border-radius 0 0 $border-radius;
                }
    
                &--flipped {
                    .proof__image {
                        border-radius: 0 $border-radius $border-radius 0;
                    }
                }
            }
        }
    }
}