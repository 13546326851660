
$mobile-bp: 432px;
$tablet-bp: 576px;

#wtm_cookie_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding: 15px;
  margin: 0;
  max-width: 640px;
  max-height: 100vw;
  overflow-y: auto;
  background-color: $color-white-default;
  z-index: 99;

  .hidden,
  &.hidden {
    display: none;
  }

  .inner {
    padding: 1.5rem;
    
    h4 {
      @extend .title-200;
      margin-bottom: 1rem;
    }

    p {
      @extend small;
    }

    .action-container {
      display: block;
      margin-top: 12px;

      .manage-link,
      .detail-link {
        margin-right: 12px;

        a,
        label {
          font-weight: normal;
          font-size: 12px;
          text-decoration: none;
          color: darkblue;
          user-select: none;
          cursor: pointer;
        }
      }

      .detail-link label[for="declaration-checkbox"] span:before {
        content: attr(data-label-show) " ";
        cursor: pointer;
      }
    }

    .button {
      @extend .button;

      @media screen and (max-width: $mobile-bp) {
        width: 100%;
        justify-content: center;
      }

      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }

    .form {
      display: flex;

      @media screen and (max-width: $tablet-bp) {
        align-items: flex-start;
      }

      @media screen and (max-width: $mobile-bp) {
        flex-direction: column;
        align-items: stretch;
      }

      &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;
        margin: 0 1rem 0 0;
        padding: 1rem;
        list-style: none;
        border: 1px solid #eee;
        border-radius: 3px;

        @media screen and (max-width: $mobile-bp) {
          flex-direction: column;
          align-content: flex-start;
          align-items: flex-start;
          margin: 0 0 10px 0;
        }

        li {
          display: inline-flex;
          align-content: center;
          box-sizing: border-box;
          margin: 0 1rem 0 0;
          list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // Resolve IE11 bug

          @media screen and (max-width: $tablet-bp) {
            width: 100%;
            margin-bottom: .5rem;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          @media screen and (max-width: $tablet-bp) {
            margin: 0;
          }

          label {
            font-size: .875rem;
            font-weight: $font-weight-medium;
            cursor: pointer;
          }

          input[type="checkbox"] {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  .declaration-container {
    display: none;
    margin: 15px 0 0 1px;
    background-color: rgb(245, 245, 245);
    font-size: 14px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 100%;

    label {
      display: inline-block;
      margin: 0 0 0 -1px;
      padding: 8px;
      background-color: rgb(245, 245, 245);
      text-decoration: none;
      color: #000;
      border: 1px solid #eee;
      cursor: pointer;

      @media screen and (max-width: $mobile-bp) {
        box-sizing: border-box;
        width: calc(50% + 1px);
      }
    }

    input {
      display: none;

      &:checked + label {
        background-color: #fff;
        border-bottom: 1px solid #fff;
      }
    }

    .declaration-table {
      box-sizing: border-box;
      width: 100%;
      table-layout: fixed;
      background-color: #fff;

      tbody {
        width: 100%;
        display: block;
        overflow: auto;
      }

      tr {
        &:not(:first-child) {
          border-top: 1px solid #eee;
        }

        th,
        td {
          padding: 4px 8px;

          &:not(:first-child) {
            border-left: 1px solid #eee;
          }

          &:last-child {
            border-right: 1px solid #eee;
          }
        }

        th {
          font-weight: bold;
        }
      }
    }
  }

  #declaration-checkbox {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }

  #declaration-checkbox:checked {
    & ~ .declaration-container {
      display: block;
    }

    & ~ .action-container .detail-link label[for="declaration-checkbox"] span:before {
      content: attr(data-label-hide) " ";
    }
  }
}
