.footer-bottom {
    background: $color-yellow-default;
    padding: 1rem 0;
    position: relative;

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 1rem 2rem;
        justify-content: space-between;
    }

    &__copyright {
        flex: 0 1 auto;
    }

    &__menu {
        flex: 0 1 auto;
        display: flex;

        @include media-breakpoint-up(md) {
            order: 2;
        }
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: .5rem 1rem;
    }

    &__item {
        background: $color-white-10;
    }

    &__link {
        color: $color-black-default
    }
}