.navbar {
    $block: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5rem;
    z-index: 50;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 2rem;
    transition: $transition-default;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 6rem;
        background: linear-gradient($color-black-60, rgba($color-black-default, 0));
        opacity: 0;
        transition: $transition-default;
    }

    &--scrolled {
        box-shadow: $elevation-700;
        background: $color-white-default;

        #{$block}__contact {
            @extend .button--yellow;
        }
    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        flex: 0 0 auto;

        svg {
            height: 1.5rem;
            width: auto;
            fill: $color-black-default;
            transition: $transition-default;
        }

        &:hover {
            svg {
                fill: $color-black-40;
            }
        }

        span {
            display: none;
        }
    }

    &__nav {
        position: relative;
        flex: 1 1 100%;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem .5rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0 0;
        transition: $transition-default;
    }

    &__link {
        color: $color-black-default;
        text-decoration: none;
        transition: $transition-default;
        font-size: 1.25rem;
        padding: .375rem 1rem;
        border-radius: 1.25rem;

        &:hover {
            background: $color-black-10;
            color: $color-black-80;
            text-decoration: none;
        }
    }

    @include media-breakpoint-down(lg) {
        &__nav {
            pointer-events: none;
        }

        &__wrapper {
            opacity: 0;
            transition-delay: opacity $transition-default-time;
            padding: 1.5rem 0 3rem;
        }

        &__list {
            width: 100%;
        }

        &__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .dropdown-menu {
                position: relative;
                background: transparent;
                border: none;
                width: 100%;
                text-align: center;
                box-shadow: none;
                margin-top: 0;

                .dropdown-item {
                    border-radius: 1.5rem;
                    font-size: 1rem;
                    font-weight: $font-weight-light;

                    &:hover {
                        background: $color-black-10;
                    }
                }
            }
        }

        &__link {
            text-align: center;
            width: 100%;
        }

        &__language {
            margin-top: 1.5rem;
        }

        &__close-label, &__close {
            display: none;
        }

        &.show {
            height: 100vh;
            background: $color-white-default;
            padding: 1rem 1rem 0 2rem;
            overflow: auto;

            #{$block}__nav {
                pointer-events: auto;
            }

            #{$block}__wrapper {
                opacity: 1;
            }

            #{$block}__list {
                gap: .5rem 1rem;
            }

            #{$block}__open-label, #{$block}__open {
                display: none;
            }

            #{$block}__close-label, #{$block}__close {
                display: block;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        flex-flow: row;

        &__header {
            width: auto;
            flex: 0 0 auto
        }

        &__toggle {
            display: none;
        }

        &__nav {
            flex: 1 1;
        }

        &__wrapper {
            flex-direction: row;
            justify-content: flex-end;
        }

        &__list {
            flex-direction: row;
            justify-content: flex-end;
        }

        &__item {
            margin: 0 .25rem;
        }

        &__link {
            font-size: 1rem;
            line-height: 1.75rem;
            font-weight: $font-weight-medium;
            padding: .5rem 1.25rem;
        }

        &__language {
            margin-left: 1rem;
            flex-direction: row;
        }
    }

    // Navbar for dark backgrounds
    &--dark:not(&--scrolled) {
        &::before {
            opacity: 1;
        }

        #{$block}__logo {
            svg {
                fill: $color-white-default;
            }

            &:hover {
                svg {
                    fill: $color-white-40;
                }
            }
        }

        #{$block}__link {
            color: $color-white-default;

            &:hover {
                background: $color-white-10;
                color: $color-white-80;
            }
        }

        #{$block}__language, #{$block}__toggle {
            @extend .button--white;
        }

        #{$block}__contact {
            @extend .button--yellow;
        }
    }
}
