.list-item {
    display: flex;

    & + & {
        margin-top: -1rem;

        @include media-breakpoint-up(lg) {
            margin-top: -1.5rem;
        }
    }

    svg {
        flex: 0 0 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        margin-top: .125rem;
        margin-right: .5rem;

        @include media-breakpoint-up(lg) {
            flex: 0 0 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: .25rem;
        }
    }

    &--check {
        svg {
            fill: $background-leaf-green;
        }
    }

    &--plus {
        svg {
            fill: $color-black-40;
        }
    }

    &--minus {
        svg {
            fill: $color-red-default;
        }
    }
}