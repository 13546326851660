.highlight-event {
    &__date {
        color: $color-black-60;
        margin-bottom: .5rem;
    }

    &__intro {
        margin-top: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 3rem;

        @include media-breakpoint-up(lg) {
            height: 4rem;
        }
    }

    &__textlink {
        margin-top: 1.5rem;
    }
}