.steps-large {
    $block: &;
    position: relative;

    &__circle {
        position: absolute;
        top: 50%;
        left: 75%;
        transform: translateY(-50%);
        width: 100vw;
        height: 100vw;
        background: $gradient-circle-green;
        opacity: .6;
    }

    &__row {
        position: relative;
        justify-content: center;
    }

    &__title {
        @extend .title-700;
        @extend .title-light;
        color: $color-black-20;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 3rem;
        }
    }

    &__step {
        display: flex;
        align-items: flex-start;
        margin-bottom: .5rem;
        border-radius: $border-radius;
        background: $background-beige;
        padding: 2rem;
        padding-left: 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 3rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 4rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__number, &__step-title {
        @extend .title-500;
    }

    &__number {
        @extend .title-light;
        color: $color-black-20;
        flex: 0 0 2rem;

        @include media-breakpoint-up(md) {
            flex: 0 0 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 3.5rem;
        }
    }

    &__content {
        margin-top: 1rem;
    }

    &.block--light {
        #{$block}__step {
            background: $background-white;
        }
    }
}