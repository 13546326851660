.highlight {
    &__title {
        color: $color-black-10;
        margin-bottom: 1rem;
    }

    &__row {
        justify-content: center;
        align-items: center;
        gap: 2rem 0;
    }

    &__image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius;
    }
}