.content {
    h1, h2, h3, h4 {
        @extend .title-light;
        position: relative;
        margin-bottom: 1rem;
        $heading: &;

        strong, b {
            @extend .title-bold;
        }

        & + h1, & + h2, & + h3, & + h4 {
            margin-top: -.5rem;
        }
    }

    h1 {
        @extend .title-500;
    }

    h2 {
        @extend .title-500;
    }

    h3 {
        @extend .title-300;
    }

    h4 {
        @extend .title-100;
    }

    ul, ol {
        padding-left: 2.5rem;

        li {
            @extend p;
            margin-bottom: 0 !important;
        }
    }

    table, ul, ol  {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style-type: disc;
    }
    
    p {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        strong, b {
            color: $color-black-default;
        }
    }

    a:not(.button) {
        color: $background-leaf-green;
    }

    .content-image, .content-video, .content-quote {
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
    }

    // Sizes
    &--large {
        h1 {
            @extend .title-800;
        }
        
        h2 {
            @extend .title-700;
        }

        h3 {
            @extend .title-500;
        }

        h4 {
            @extend .title-200;
        }

        p {
            @extend .lead;
        }
    }
    
    &--small {
        h1 {
            @extend .title-400;
        }
        
        h2 {
            @extend .title-300;
        }

        h3 {
            @extend .title-100;
        }
    }

    // Colours
    &--default, &--light {
        .buttons__primary {
            @extend .button--yellow;
        }
    }

    &--purple, &--yellow, &--turquoise, &--orange {
        .list-item svg {
            fill: $color-white-default;
        }
    }

    &--dark {
        h1, h2, h3, h4 {
            color: $color-yellow-default;
        }

        p {
            color: $color-white-80;

            strong, b {
                color: $color-white-default;
            }
        }
        
        .buttons__primary {
            @extend .button--yellow;
        }
        
        .buttons__secondary {
            @extend .button--white;
        }

        a:not(.button) {
            color: $color-yellow-default;
        }
    }
    
    &--image {
        h1, h2, h3, h4 {
            color: $color-white-default;
        }

        p {
            color: $color-white-80;

            strong, b {
                color: $color-white-default;
            }
        }

        .buttons__primary {
            @extend .button--white;
        }
        
        .buttons__secondary {
            @extend .button--white;
        }
    }
}