.header-home, .header-landing {
    $block: &;
    position: relative;
    background: $color-yellow-default;
    overflow: hidden;

    &__circle {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vw;

        &--one {
            top: -25%;
            left: 100%;
            background: $gradient-circle-turquoise;
        }

        &--two {
            top: 0;
            left: 0;
            background: $gradient-circle-orange;
            opacity: .6
        }

        &--three {
            top: 100%;
            left: 100%;
            background: $gradient-circle-purple;
            opacity: .8;
        }
    }

    &__line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150%;
        height: auto;

        @include media-breakpoint-up(sm) {
            width: 110%;
        }
    }

    &__image {
        position: relative;
        object-position: bottom center;
        object-fit: contain;
        width: 100%;
        aspect-ratio: 1/1;
        z-index: 1;
        margin-top: -4rem;
        margin-left: 10%;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
            margin-left: 0;
            position: absolute;
            top: $navbar-height;
            bottom: 0;
            left: 50%;
            right: 0;
            height: calc(100% - 5rem);
            width: 75%;
        }

        @include media-breakpoint-up(md) {
            width: 60%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }

    &__content {
        @include block-padding--large;
        position: relative;
        z-index: 2;
    }
}

.header-landing {
    background: $background-beige;
}