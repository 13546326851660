.icon-boxes {
    $block: &;
    display: flex;
    flex-flow: row wrap;
    border-radius: $border-radius;
    overflow: hidden;
    
    &__box {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 2rem 2rem;

        @include media-breakpoint-up(md) {
            flex: 1 1 33.333%;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem 3rem 3rem;
        }
    }

    svg {
        height: 3rem;
        width: 3rem;
        margin-bottom: 1rem;
        fill: $color-black-10;

        @include media-breakpoint-up(md) {
            height: 4rem;
            width: 4rem;
        }
    }

    &__title, &__text {
        max-width: 24rem;
        text-align: center;
    }

    &__text {
        margin-top: .5rem;
    }

    &__link {
        margin-top: 1rem;
    }

    // Colours
    &--light {
        background: $background-beige;
    }

    &--yellow {
        background: $color-yellow-default;
    }

    &--turquoise {
        background: $color-turquoise-default;
    }

    &--orange {
        background: $color-orange-default;
    }

    &--purple {
        background: $color-purple-default;
    }

    &--dark {
        background: $background-reassurance-green;

        svg {
            fill: $color-white-10;
        }

        #{$block}__title {
            color: $color-yellow-default;
        }
        
        #{$block}__text {
            color: $color-white-default;
        }
        
        #{$block}__link {
            @extend .textlink--yellow;
        }
    }
}