.form-check {
    flex: 1 1 100%;
    margin-bottom: 0;
    padding-left: 2rem;

    // Sizing
    .form-check-input {
        margin-left: -2rem;
        height: 1.5rem;
        width: 1.5rem;
        margin-top: 0;

        @include media-breakpoint-up(lg) {
            margin-top: .25rem;
        }
    }

    // Typography
    .form-check-label {
        @extend p;
    }

    // Colouring
    .form-check-label {
        color: $color-black-80;
    }

    .form-check-input {
        background-color: transparent;
        border-color: $color-black-20;
        box-shadow: $shadow-black-100;
        transition: $transition-short;

        &:hover {
            box-shadow: $shadow-black-200;
            border-color: $color-black-40;
        }

        &:focus {
            box-shadow: $shadow-black-300, 0 0 0 .25rem $color-yellow-20;
        }

        &:checked {
            background-color: $color-yellow-darker;
            border-color: $color-black-10;
        }
    }
}

.form--dark {
    .form-check {
        .form-check-label {
            color: $color-white-80;
        }

        .form-check-input {
            border-color: $color-white-20;
            box-shadow: $shadow-white-100;

            &:hover {
                box-shadow: $shadow-white-200;
                border-color: $color-white-40;
            }

            &:focus {
                box-shadow: $shadow-white-300, 0 0 0 .25rem $color-yellow-20;
            }
        }
    }
}
