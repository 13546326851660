.card-story {
    &__image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }

    &__client {
        color: $color-black-60;
    }

    &__title {
        margin-top: .25rem;
    }

    &__textlink {
        margin-top: 1rem;
    }
}