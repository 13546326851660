﻿@mixin paragraph {
    font-size: 1rem;
    line-height: 1.5;

    @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
        line-height: 2rem;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 1.25rem;
        line-height: 2rem;
    }
}

@mixin paragraph-lead {
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

@mixin paragraph-small {
    display: block;
    font-size: .875rem;
    line-height: 1.5rem;

    @include media-breakpoint-up(xl) {
        font-size: 1rem;
        line-height: 1.75rem;
    }
}