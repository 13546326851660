.form-help {
    $block: &;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: .75rem 0;

    &__row {
        display: flex;
        margin: 0 -.375rem;
    }

    &__col-66 {
        flex: 1 1 66.666%;
        padding: 0 .375rem;
    }

    &__col-50 {
        flex: 1 1 50%;
        padding: 0 .375rem;
    }

    &__col-33 {
        flex: 1 1 33.333%;
        padding: 0 .375rem;
    }

    &__title {
        color: $color-black-default;
    }

    &__submit {
        @extend .button;
        @extend .button--icon-right;
        @extend .button--large;
        @extend .button--yellow;
        flex: 1 1 100%;
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
            flex: 0 0 auto;
        }
    }

    &--dark {
        #{$block}__title {
            color: $color-yellow-default;
        }
    }

    &.hidden {
        display: none;
    }
}
