.image-fullscreen {
    $block: &;
    position: relative;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        aspect-ratio: 21/9;
    }

    &__image {
        @include position-absolute;
        object-fit: cover;
    }

    &__circle {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vw;

        &--one {
            top: -25%;
            left: 0;
            background: $gradient-circle-purple;
        }

        &--two {
            top: 0%;
            left: 100%;
            background: $gradient-circle-yellow;
            opacity: .6
        }

        &--three {
            top: 100%;
            left: 0;
            background: $gradient-circle-dark;
        }
    }

    &__content {
        @include block-padding--large;
        position: relative;
        text-shadow: 0 .25rem 1.5rem $color-black-80;
    }
}