.product-tour {
    $block: &;

    &__row {
        justify-content: center;
    }

    &__header {
        text-align: center;
        margin-bottom: 3rem;
    }

    &__title {
        @extend .title-200;
        @extend .title-light;
        margin-bottom: .25rem;
        color: $color-black-40;
    }

    &__subtitle {
        @extend .title-600;
        margin-bottom: 0;
    }

    &__intro {
        @extend .lead;
        margin-bottom: 0;
        margin-top: 1rem;
        color: $color-black-60;
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 2rem;

        @include media-breakpoint-up(lg) {
            gap: 3rem;
        }
    }

    &__features {
        flex: 1 1 100%;
        display: flex;
        flex-flow: row wrap;

        @include media-breakpoint-up(sm) {
            flex: 1 1;
        }
    }

    &__feature {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        flex: 1 1 100%;
        
        // Animation
        opacity: 0;
        visibility: hidden; /* Keeps the element out of view without removing it from the flow */
        height: 0; /* Ensure the element collapses */
        overflow: hidden;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out;

        &.active {
            // Animation
            opacity: 1;
            visibility: visible; /* Make the element visible */
            height: auto; /* Restore original size */
        }
    }

    &__content {
        flex: 1 1 100%;
        padding: 1.5rem;

        @include media-breakpoint-up(lg) {
            flex: 1 1;
            padding: 1.5rem 0;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 1 26rem;
        }
    }

    &__image-wrapper {
        flex: 1 1 100%;

        @include media-breakpoint-up(lg) {
            flex: 0 0 60%;
        }

        @include media-breakpoint-up(xl) {
            flex: 1 1;
        }
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__menu {
        background: $color-white-default;
        border-radius: $border-radius;
        border: solid .0625rem $color-black-10;
        box-shadow: $shadow-black-100;
        flex: 1 1 100%;

        @include media-breakpoint-up(sm) {
            flex: 0 0 40%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 30%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 20rem;
        }
    }

    &__menu-title {
        @extend .title-100;
        @extend .title-light;
        color: $color-black-40;
        padding: 1.5rem 1.5rem 0;
    }

    &__list {
        padding: 1rem 0;
        display: flex;
        flex-flow: row wrap;
    }

    &__item {
        flex: 1 1 100%;
    }

    &__link {
        @extend .title-100;
        @extend .title-medium;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .25rem;
        text-decoration: none;
        padding: .75rem 1rem .75rem 1.5rem;
        color: $color-black-default;
        transition: $transition-default;

        &:hover {
            padding: .75rem 1.5rem;
            background: $color-black-05;
            color: $color-black-60;

            svg {
                opacity: 1;
            }
        }

        &.active {
            background: $color-yellow-20;
            color: $color-yellow-darker;

            &:hover {
                background: $color-yellow-20;
                color: $color-yellow-darker;

                svg {
                    opacity: 0;
                }
            }
        }

        svg {
            opacity: 0;
            transition: $transition-default;
            fill: $color-black-default;
        }
    }
}
