// Black & White
$color-white-default: #FAFAF9;
$color-white-90: rgba($color-white-default, .9);
$color-white-80: rgba($color-white-default, .8);
$color-white-60: rgba($color-white-default, .6);
$color-white-40: rgba($color-white-default, .4);
$color-white-20: rgba($color-white-default, .2);
$color-white-10: rgba($color-white-default, .1);
$color-white-05: rgba($color-white-default, .05);


$color-black-default: #0B0B0A;
$color-black-90: rgba($color-black-default, .9);
$color-black-80: rgba($color-black-default, .8);
$color-black-60: rgba($color-black-default, .6);
$color-black-40: rgba($color-black-default, .4);
$color-black-20: rgba($color-black-default, .2);
$color-black-10: rgba($color-black-default, .1);
$color-black-05: rgba($color-black-default, .05);

// Colors
$color-red-default: #FA6545;
$color-red-darker: #E23712;
$color-red-80: rgba($color-red-default, .8);
$color-red-60: rgba($color-red-default, .6);
$color-red-40: rgba($color-red-default, .4);
$color-red-20: rgba($color-red-default, .2);

$color-orange-default: #FE9900;
$color-orange-darker: #E08700;
$color-orange-80: rgba($color-orange-default, .8);
$color-orange-60: rgba($color-orange-default, .6);
$color-orange-40: rgba($color-orange-default, .4);
$color-orange-20: rgba($color-orange-default, .2);

$color-yellow-default: #FEEA27;
$color-yellow-darker: #D0BF12;
$color-yellow-80: rgba($color-yellow-default, .8);
$color-yellow-60: rgba($color-yellow-default, .6);
$color-yellow-40: rgba($color-yellow-default, .4);
$color-yellow-20: rgba($color-yellow-default, .2);

$color-green-default: #6CD206;
$color-green-darker: #60BA07;
$color-green-80: rgba($color-green-default, .8);
$color-green-60: rgba($color-green-default, .6);
$color-green-40: rgba($color-green-default, .4);
$color-green-20: rgba($color-green-default, .2);

$color-turquoise-default: #A7E3D9;
$color-turquoise-darker: #64B7A9;
$color-turquoise-80: rgba($color-turquoise-default, .8);
$color-turquoise-60: rgba($color-turquoise-default, .6);
$color-turquoise-40: rgba($color-turquoise-default, .4);
$color-turquoise-20: rgba($color-turquoise-default, .2);

$color-blue-default: #5FB2FF;
$color-blue-darker: #2C92F0;
$color-blue-80: rgba($color-blue-default, .8);
$color-blue-60: rgba($color-blue-default, .6);
$color-blue-40: rgba($color-blue-default, .4);
$color-blue-20: rgba($color-blue-default, .2);

$color-purple-default: #B990F0;
$color-purple-darker: #8E51E1;
$color-purple-80: rgba($color-purple-default, .8);
$color-purple-60: rgba($color-purple-default, .6);
$color-purple-40: rgba($color-purple-default, .4);
$color-purple-20: rgba($color-purple-default, .2);

$color-pink-default: #F8AFEC;
$color-pink-darker: #DF73CE;
$color-pink-80: rgba($color-pink-default, .8);
$color-pink-60: rgba($color-pink-default, .6);
$color-pink-40: rgba($color-pink-default, .4);
$color-pink-20: rgba($color-pink-default, .2);

$color-rose-default: #FF78A0;
$color-rose-darker: #E14775;
$color-rose-80: rgba($color-rose-default, .8);
$color-rose-60: rgba($color-rose-default, .6);
$color-rose-40: rgba($color-rose-default, .4);
$color-rose-20: rgba($color-rose-default, .2);

// Backgrounds
$background-white: #F7F6ED; // Default
$background-beige: #F1F0E2;
$background-sand: #E9E5D0;
$background-growth-green: #C9D6A8;
$background-leaf-green: #93A96A;
$background-reassurance-green: #142A03;
