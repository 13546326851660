@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/forms";

@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/dropdown";

.container {
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @include media-breakpoint-up(md) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}