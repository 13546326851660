.legal {
    $block: &;

    &__row {
        flex-direction: row-reverse;
        justify-content: center;
        gap: 3rem 0;

        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
    }

    &__nav {
        background: $color-white-default;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: $elevation-300;
    }

    &__item {
        border-bottom: solid .0625rem $color-black-10;

        &:last-of-type {
            border-bottom: none;
        }

        ul {
            display: none;

            li {
                border-bottom: none;

                #{$block}__link {
                    padding: .375rem 1.5rem;
                    font-weight: $font-weight-light;
                }

                &:last-of-type {
                    padding-bottom: .5rem
                }
            }
        }

        &.ancestor, &.active {
            ul {
                display: block;
            }
        }

        &.active {
            background: $color-yellow-20;
        }
    }

    &__link {
        display: block;
        padding: .75rem 1.5rem;
        color: $color-black-default;
        text-decoration: none;
        font-weight: $font-weight-medium;

        &:hover {
            background: $color-black-10;
            color: $color-black-60;
        }

        &--parent {
            @extend .title-100;
            padding: 1rem 1.5rem;
            font-weight: $font-weight-bold;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: 3rem 0;
    }

    &__header, &__content, &__form {
        flex: 1 1 100%;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__last-updated {
        color: $background-leaf-green;
        font-weight: $font-weight-medium;

        span {
            font-weight: $font-weight-light;
            margin-right: .375rem;
        }
    }

    &__form {
        background: $background-reassurance-green;
        border-radius: $border-radius;
        padding: 2rem;

        @include media-breakpoint-up(md) {
            padding: 3rem;
        }

        .form__submit {
            margin-top: 0;
        }
    }

    table {
        border: 1px solid;
        border-collapse: collapse;
        margin-bottom: 1rem;
        margin-top: 1rem;

        th, td {
            border: 1px solid;
            padding: 5px;
            font-size: 1rem;
            
            @include media-breakpoint-up(lg) {
                font-size: 1.25rem;
            }
        }
        
        th {
            font-weight: $font-weight-medium;
        }
        
        td {
            font-weight: $font-weight-light;

            &.htTop{
                vertical-align: top;
            }
        }
    }

    ol {
        ol {
            list-style-type: lower-alpha;
        }
    }
}
