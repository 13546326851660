.header-page {
    $block: &;
    position: relative;
    overflow: hidden;

    &__image {
        @include position-absolute;
        object-fit: cover;
    }

    &__container {
        @include block-padding--large;
    }

    &__circle {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 150vw;
        height: 150vw;
        top: 50%;
        left: 0;
        background: $gradient-circle-dark;
    }

    &__line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: auto;

        @include media-breakpoint-up(xl) {
            width: 110%;
        }
    }

    &__content {
        position: relative;
        text-align: center;
    }

    &__title {
        @extend .title-800;
        color: $color-white-default;
        text-align: center;
        margin-bottom: 1rem;
    }
}