.faq {
    &__row {
        justify-content: center;
    }

    &__title {
        margin-bottom: 1.5rem;
    }
}

.faq-category {
    $block: &;

    &__row {
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            border-bottom: solid .0625rem $color-black-10;
            margin-top: 1.5rem;
            margin-bottom: -1.5rem;

            @include media-breakpoint-up(md) {
                margin-top: 2rem;
                margin-bottom: -2rem;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 3rem;
                margin-bottom: -3rem;
            }
        }
    }

    &__title {
        padding: .5rem 0;
    }

    &--last {
        #{$block}__row {
            &::after {
                display: none;
            }
        }
    }
}

.legal__content{
    .faq__row{
        margin-left: 0;
        margin-right: 0;
        justify-content: left;
    }
    .faq .container {
        padding-left: 0;
        padding-right: 0;
    }
    section.block.faq {
        padding-top: 0;
    }
    h2.title-600{
        font-weight: $font-weight-bold;
    }
    h2.title-200{
        font-size: 1.5rem;
        font-weight: $font-weight-medium;
    }
    .col-lg-16{
        width: 100%;
    }
}
