@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Light.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-LightItalic.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Bold.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-BoldItalic.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-BoldItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Super.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-Super.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-SuperItalic.woff2') format('woff2'),
        url('https://cdn-eu-aws.slaimg.com/fonts/WhyteInktrap/ABCWhyteInktrap-SuperItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
