.image-text {
    $block: &;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;

    &__image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: $border-radius $border-radius 0 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            height: 100%;
            width: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 50%;
            border-radius: $border-radius 0 0 $border-radius;
        }
    }

    &__row {
        justify-content: flex-end;
    }

    &__content {
        @include block-padding--large;
    }

    &__prefix {
        @extend .title-200;
        @extend .title-light;
        color: $color-black-40;
        margin-bottom: .5rem;
    }

    // Flipped
    &--flipped {
        #{$block}__row {
            justify-content: flex-start;
        }
        
        #{$block}__image {
            @include media-breakpoint-up(md) {
                left: 50%;
                right: 0;
                border-radius: 0 $border-radius $border-radius 0;
            }
        }
    }

    // Colours
    &--light {
        background: $background-beige;
    }
    
    &--dark {
        background: $background-reassurance-green;
    }
    
    &--yellow {
        background: $color-yellow-default;
    }
    
    &--turquoise {
        background: $color-turquoise-default;
    }
    
    &--orange {
        background: $color-orange-default;
    }
    
    &--purple {
        background: $color-purple-default;
    }
}

.block--light {
    .image-text--light {
        background: $background-white;
    }
}