.numbers-slider {
    overflow: visible;

    &__row {
        flex-wrap: nowrap;
    }

    &__title {
        @extend .title-500;
        margin-bottom: .5rem;
        text-align: center;
    }

    &__paragraph {
        color: $color-black-60;
        text-align: center;
    }
}